import React, { Component } from 'react'
import { withStyles, Theme, Grid, CircularProgress, ButtonBase, Typography, Modal, Paper, IconButton, Divider, Snackbar, TextField, Button, Card, CardHeader, CardContent } from '@material-ui/core';
import { get, post } from '../components/request';
import cardData from '../services/cardData';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Close, Delete } from '@material-ui/icons';

import MySnackbarContentWrapper from '../components/SnackbarContentWrapper';
import config from '../config';

const style = (theme: Theme): Record<string, CSSProperties> => ({
  imgClass: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.up('md')]: {
      height: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
    '&:hover': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
})

interface TipsEditState {
  data: cardData[],
  loading: boolean,
  contentArticle: {
    open: boolean,
    header: string,
    content: string
  },
  password: string,
  snackBarError: boolean,
  loginModal: boolean,
}

class TipsEdit extends Component<any> {

  state: TipsEditState = {
    data: [],
    loading: true,
    contentArticle: {
      open: false,
      header: '',
      content: ''
    },
    password: '',
    snackBarError: false,
    loginModal: true,
  }

  componentDidMount() {
    this.fetchTips();
  }

  fetchTips = () => {
    get('techTips')
      .then(res => res.json())
      .then((data: cardData[]) => {
        this.setState({ loading: false, data });
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleRemove = (el: any) => {
    post('removeTip', { id: el.id, password: this.state.password })
      .then(res => res.json())
      .then((res: any) => {
        if (res.ok) {
          this.fetchTips();
        }
      })
      .catch(err => this.setState({ snackBarError: true }))
  }

  handleOpenModal = (el: cardData) => () => {
    this.setState({
      contentArticle: {
        open: true,
        header: el.header,
        content: el.content
      }
    });
  }

  handleAuthentication = () => {
    const {password} = this.state;
    if(password.length) {
      post('authenticate', { password })
      .then(res => res.json())
      .then((res: any) => {
        if (res.ok) {
          this.setState({loginModal: false})
        }
        else {
          this.setState({ snackBarError: true })
        }
      })
      .catch(err => this.setState({ snackBarError: true }))
    }
  }

  handleChange = (name: any) => (event: any) => {
    this.setState({ [name]: event.target.value });
  }

  handleCloseModal = () => {
    this.setState({ contentArticle: { open: false } })
  }

  handleCloseSnackBar = () => {
    this.setState({ snackBarError: false });
  }

  render() {
    const { loading, data, contentArticle, loginModal, password } = this.state;
    const { classes } = this.props;

    const loader = (
      <div style={{ marginTop: 50, padding: 20 }}>
        <CircularProgress
          style={{ display: 'block' }}
          size='70px'
          color='primary'
        />
      </div>
    );

    return (
      <Grid container>
        <Modal open={loginModal}> 
          <Grid container justify="center" alignItems='center' style={{ height: '100vh' }}>
            <Grid item xs={12} sm={7} style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }} >
              <Paper style={{ verticalAlign: 'center', padding: 10, position: 'relative' }} component="article" elevation={3} square>

                <Typography variant='h6' align='center'>
                  Logowanie:
                </Typography>
                <TextField fullWidth label="Hasło:" onChange={this.handleChange('password')} type='password' value={password} required className={classes.input} variant='outlined' /><br />

                <Button onClick={this.handleAuthentication} fullWidth variant='contained' color='secondary'>Zaloguj</Button>
              </Paper>
            </Grid>
          </Grid>
        </Modal>


        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackBarError}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSnackBar}
            variant="error"
            message="BŁĄD"
          />
        </Snackbar>

        <Modal open={contentArticle.open}>
          <Grid container justify="center" alignItems='center' style={{ height: '100vh' }}>
            <Grid item xs={12} sm={7} style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }} >
              <Paper style={{ verticalAlign: 'center', padding: 10, position: 'relative' }} component="article" elevation={3} square>
                <IconButton onClick={this.handleCloseModal} style={{ position: "absolute", right: 5, top: 5 }}>
                  <Close />
                </IconButton>
                <Typography
                  variant="title"
                  align="center"
                  color="inherit"
                >
                  {contentArticle.header}
                </Typography>
                <Divider variant="middle" style={{ margin: '20px 0' }} />
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  paragraph
                  className={classes.modalParagraph}
                >
                  {contentArticle.content}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Modal>

        <Grid item sm={6}>
          <Grid container justify="center" alignItems='center'>
            {
              loading ? loader : data.map(
                (el: cardData, i: number) =>
                  <Grid
                    item
                    xs={12}
                    key={`thumb-${i}`}
                    style={{ padding: 20, position: 'relative' }}
                  >
                    <IconButton onClick={() => this.handleRemove(el)} style={{ position: 'absolute', right: 30, top: 30, zIndex: 123, padding: 15, backgroundColor: 'white' }}>
                      <Delete color='primary' />
                    </IconButton>
                    <ButtonBase
                      focusRipple
                      key={`image-${i}`}
                      className={classes.imgClass}
                      focusVisibleClassName={classes.focusVisible}
                      style={{ width: '100%' }}
                    >
                      <span
                        className={classes.imageSrc}
                        style={{
                          backgroundImage: `url(${el.img})`,
                        }}
                      />
                      <span className={classes.imageBackdrop} />
                      <span className={classes.imageButton}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          color="inherit"
                          className={classes.imageTitle}
                          onClick={this.handleOpenModal(el)}
                        >
                          {el.header}
                          <span className={classes.imageMarked} />
                        </Typography>
                      </span>
                    </ButtonBase>
                  </Grid>
              )
            }
          </Grid>
        </Grid>

        <Grid item sm={6}>
            <Card style={{ maxWidth: 450, margin: 'auto' }}>
              <CardHeader style={{ textAlign: 'center' }} title='Dodaj nową poradę:' />
              <CardContent>
                <form action={config.serverAddress + '/addTip'} method="POST" encType="multipart/form-data">
                  <input style={{ display: 'none' }} name='password' type="text" value={password} readOnly/>
                  <label htmlFor="raised-button-file">
                    <Button variant='contained' component="span" className={classes.button}>
                      Wybierz zdjęcie
                    </Button>
                  </label><br />
                  <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    name='image'
                  />
                  <h3>Nagłówek:</h3><input type="text" name='header' id="header" style={{fontSize: 24}} placeholder="Rodzaje blachy" /><br />
                  <h3>Treść artykułu: </h3><textarea style={{fontSize: 24}} name='content' id='content' placeholder="Blachy dzielimy na..." cols={35} rows={15}></textarea><br />
                  <input type="submit" style={{ display: 'none' }} id="raised-button-submit" value='Dodaj!'/>
                  <label htmlFor="raised-button-submit">
                  <Button variant='contained' component="span" className={classes.button}>
                    Dodaj
                  </Button>
                </label>
                </form>
              </CardContent>
            </Card>
        </Grid>

      </Grid>
    )
  }
}
export default withStyles(style)(TipsEdit);