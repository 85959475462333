import imageElem from '../services/imageElem';

const images: imageElem[] = [
    {
      src: "img/01.png",
      width: 33,
      height: 194,
      item_name: 'Rura 1m',
      item_description: 'rura o długości 1m',
      item_length: 1000,
    },
    {
      src: "img/02.png",
      width: 33,
      height: 100,
      item_name: 'Rura 0.5m',
      item_description: 'rura o długości 50cm',
      item_length: 500,
    },
    {
      src: "img/03.png",
      width: 33,
      height: 54,
      item_name: 'Rura 0.25m',
      item_description: 'rura o długości 25cm',
      item_length: 250,
    },
    {
      src: "img/04.png",
      width: 61,
      height: 79,
      item_name: 'Parasol komina',
      item_description: 'Parasol komina, przykrywa komin i uniemozliwia wlot śniegu.',
      item_length: 0,
    },
    {
      src: "img/05.png",
      width: 94,
      height: 30,
      item_name: 'Płyta kominowa',
      item_description: 'Płyta kominowa, jest pod parasol komina i uniemozliwia wlot śniegu.',
      item_length: 0,
    },
    {
      src: "img/06.png",
      width: 62,
      height: 93,
      item_name: 'Trójnik 90º',
      item_description: 'Trójnik, pomiędzy rurami i umozliwia odporawadzanie spalin.',
      item_length: 340,
    },
    {
      src: "img/07.png",
      width: 83,
      height: 127,
      item_name: 'Trójnik 45º',
      item_description: 'Trójnik, pomiędzy rurami i umozliwia odporawadzanie spalin.',
      item_length: 425,
    },
    {
      src: "img/08.png",
      width: 83,
      height: 127,
      item_name: 'Wyczystka prostokątna',
      item_description: 'Trójnik, pomiędzy rurami i umozliwia odporawadzanie spalin.',
      item_length: 345,
    },
    {
      src: "img/09.png",
      width: 104,
      height: 127,
      item_name: 'Wyczystka okrągła z zaśl',
      item_description: 'Trójnik, pomiędzy rurami i umozliwia odporawadzanie spalin.',
      item_length: 340,
    },
    {
      src: "img/10.png",
      width: 50,
      height: 100,
      item_name: 'Odskraplacz',
      item_description: 'Trójnik, pomiędzy rurami i umozliwia odporawadzanie spalin.',
      item_length: 80,
    },
    {
      src: "img/14.png",
      width: 83,
      height: 127,
      item_name: 'Blacha kominowa',
      item_description: 'Trójnik, pomiędzy rurami i umozliwia odporawadzanie spalin.',
      item_length: 0,
    },
  ];

  export default images;