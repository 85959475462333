import React, { Component } from 'react'
import { withStyles, Grid, Card, Typography, CircularProgress, ButtonBase, Modal, Paper, Divider, IconButton, Grow } from '@material-ui/core';
import { get } from '../components/request';
import { Close } from '@material-ui/icons';

import cardData from '../services/cardData';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const style = (theme: any): Record<string, CSSProperties> => ({
  Wrapper: {},
  imgWrp: {
    width: '100%',
    height: 350,
    background: 'url(/img/tech.svg) 0 0',
    backgroundColor: '#b1a5a5ab',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  input: {
    marginBottom: 5,
  },
  mapGrid: {
    [theme.breakpoints.only('md')]: {
      marginTop: -100,
      height: 500
    }
  },
  imgClass: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.up('md')]: {
      height: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
    '&:hover': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  modalParagraph: {
    '&:first-letter': {
      fontSize: '2em',
    }
  },
});

interface TechTipsState {
  loading: boolean,
  data: cardData[],
  contentArticle: {
    open: boolean,
    header: string,
    content: string
  }
}

class TechTips extends Component<any> {
  state: TechTipsState = {
    loading: true,
    data: [],
    contentArticle: {
      open: false,
      header: '',
      content: ''
    }
  }

  componentDidMount() {
    get('techTips')
      .then(res => res.json())
      .then((data: cardData[]) => {
        this.setState({ loading: false, data });
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleOpenModal = (el: cardData) => () => {
    this.setState({
      contentArticle: {
        open: true,
        header: el.header,
        content: el.content
      }
    });
  }

  handleCloseModal = () => {
    this.setState({contentArticle: {open: false}})
  }

  render() {
    const { classes } = this.props;
    const { loading, data, contentArticle } = this.state;

    const loader = (
      <div style={{ marginTop: 50, padding: 20 }}>
        <CircularProgress
          style={{ display: 'block' }}
          size='70px'
          color='primary'
        />
      </div>
    );

    return (
      <div className={classes.Wrapper}>
        <div className={classes.imgWrp}>
          <Grid style={{ height: 350 }} container justify="center" alignItems='center'>
            <Card style={{ borderRadius: 0 }}>
              <Typography style={{ padding: 20 }} variant="h2" align='center' color="primary">
                Porady techniczne
              </Typography>
            </Card>
          </Grid>
        </div>

        <Modal open={contentArticle.open}>
          <Grid container justify="center" alignItems='center' style={{height: '100vh'}}>
            <Grid item xs={12} sm={7} style={{maxHeight: 'calc(100vh - 200px)', overflow: 'auto'}} >
              <Paper style={{verticalAlign: 'center', padding: 10, position: 'relative'}} component="article" elevation={3} square>
                <IconButton onClick={this.handleCloseModal} style={{position: "absolute", right: 5, top: 5}}>
                  <Close />
                </IconButton>
                <Typography
                  variant="title"
                  align="center"
                  color="inherit"
                >
                  {contentArticle.header}
                </Typography>
                <Divider variant="middle" style={{margin: '20px 0'}} />
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  paragraph
                  className={classes.modalParagraph}
                >
                  {contentArticle.content}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Modal>

        <Grid container justify="center" alignItems='center'>
          {
            loading ? loader : data.map(
              (el: cardData, i: number) =>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  key={`thumb-${i}`}
                >
                  <Grow in timeout={300*i + 150}>
                    <ButtonBase
                      focusRipple
                      key={`image-${i}`}
                      className={classes.imgClass}
                      focusVisibleClassName={classes.focusVisible}
                      style={{ width: '100%' }}
                      onClick={this.handleOpenModal(el)}
                    >
                      <span
                        className={classes.imageSrc}
                        style={{
                          backgroundImage: `url(${el.img})`,
                        }}
                      />
                      <span className={classes.imageBackdrop} />
                      <span className={classes.imageButton}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          color="inherit"
                          className={classes.imageTitle}
                        >
                          {el.header}
                          <span className={classes.imageMarked} />
                        </Typography>
                      </span>
                    </ButtonBase>
                  </Grow>
                </Grid>
            )
          }
        </Grid>
      </div>
    )
  }
}

export default withStyles(style)(TechTips);