import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ScrollMagic from 'scrollmagic';
import $ from 'jquery';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
} from 'react-router-dom';

import { 
  Hidden, 
  Drawer, 
  Divider, 
  CssBaseline, 
  AppBar, 
  IconButton, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Toolbar,
  Typography, 
  Button } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';

import { Home as HomeIcon,
  LibraryBooks as LibraryBooksIcon,
  Build as BuildIcon,
  Menu as MenuIcon,
  SupervisorAccount as SupervisorAccountIcon,
 } from '@material-ui/icons';

import Home from './pages/Home';
import Configurator from './pages/Configurator';
import TechTips from './pages/TechTips';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Rodo from "./pages/Rodo";
import TipsEdit from "./pages/TipsEdit";

import Routes from './services/Routes'
import src from '*.svg';

const drawerWidth = 240;

const styles = (theme: Theme) => ({
  mainWrapper: {
    flex: 1,
  },
  siteName: {
    marginLeft: -50,
    size: 2
  },
  identityBackground: {
    backgroundColor: '#00000090',
    width: 400,
    marginTop: 30,
    marginBottom:30, 
  },
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuItems: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
  },
});

class App extends Component<any, any>{

  static propTypes = {
    classes: PropTypes.object.isRequired,
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
  };

  state = {
    mobileOpen: false,
    currentPath: window.location.pathname,
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleAddressChange = (path: string) => {
    this.setState({currentPath: path})
  }

  componentDidMount = () => {
    $(window).bind('scroll', function () {
      if ($(window).scrollTop() > 100) {
          $("header").css({ "transition": "all .5s ease" }).addClass("thickbar");
      } else {
          $("header").css({ "transition": "all .5s ease" }).removeClass("thickbar");
      }
  });

    // $("header").mouseenter(function(){
    //   $("header").addClass("thickbar");
    // })
    }
  render() {
    const { currentPath } = this.state;
    const { classes } = this.props;
    const { pathname } = window.location;
    const activeStyle = {
      backgroundColor: '#00000015',
      textDecoration: 'none',
    };
    const activeItem = {
      backgroundColor: '#ffffffaf',
      textDecoration: 'none',
    };

    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <img src="/img/logo.png" alt=""/>
        <Typography variant="subtitle2" color="inherit" noWrap>
          MD Wkłady Kominowe
        </Typography>
        <List>
          <Divider />
            <Link onClick={this.handleDrawerToggle} style={{textDecoration: 'none'}} to={Routes.home}>
          <ListItem button style={pathname === Routes.home ? activeStyle : undefined}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Strona główna"} />
          </ListItem>
          </Link>
          <Divider />
            <Link onClick={this.handleDrawerToggle} style={{textDecoration: 'none'}} to={Routes.configurator}>
          <ListItem button style={pathname === Routes.configurator ? activeStyle : undefined}>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary={"Konfigurator"} />
          </ListItem>
          </Link>
          <Divider />
            <Link onClick={this.handleDrawerToggle} style={{textDecoration: 'none'}} to={Routes.tech_tips}>
          <ListItem button style={pathname === Routes.tech_tips ? activeStyle : undefined}>
            <ListItemIcon>
              <LibraryBooksIcon />
            </ListItemIcon>
            <ListItemText primary={"Porady techniczne"} />
          </ListItem>
          </Link>
          <Divider />
            <Link color="inherit" onClick={this.handleDrawerToggle} style={{textDecoration: 'none'}} to={Routes.about_us}>
          <ListItem button style={pathname === Routes.about_us ? activeStyle : undefined}>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary={"O nas"} />
          </ListItem>
          </Link>
          <Divider />
            <Link color="primary" onClick={this.handleDrawerToggle} style={{textDecoration: 'none'}} to={Routes.contact}>
              <ListItem button style={pathname === Routes.contact ? activeStyle : undefined}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Kontakt"} />
              </ListItem>
            </Link>
          <Divider />
        </List>
      </div>
    );

    const itemStyles = {
      textDecoration: 'none',
      marginLeft: 3,
      marginRight: 3,
    };
    const navBar = {
     height: 200, 
    };
   


    return (
      <Router>
        <div className={classes.mainWrapper}>

          <div className={classes.root} >
            <CssBaseline />
            <AppBar style={navBar} position="fixed" className={classes.appBar}>
              <Toolbar>
              
                <Typography style={{flex: 1,}} variant="h6" color="inherit" noWrap>
                  <p className={classes.identityBackground}>
                     <img src="/img/logo.png" height="128px" alt=""/>
                     <span className={classes.siteName}>MD Wkłady Kominowe</span>
                  </p>
                </Typography>

                <IconButton
                  color="inherit"
                  onClick={this.handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>

                <div className={classes.menuItems}>
                  <Link style={itemStyles} onClick={() => this.handleAddressChange(Routes.home)} color="inherit" to={Routes.home}>
                    <Button style={currentPath === Routes.home ? activeItem : {color: 'white'}}>
                        Strona główna
                    </Button>
                  </Link>
                  <Link style={itemStyles} onClick={() => this.handleAddressChange(Routes.tech_tips)} color="inherit" to={Routes.tech_tips}>
                    <Button style={currentPath === Routes.tech_tips ? activeItem : {color: 'white'}}>
                        Porady Techniczne
                    </Button>
                  </Link>
                  <Link style={itemStyles} onClick={() => this.handleAddressChange(Routes.about_us)} color="inherit" to={Routes.about_us}>
                    <Button style={currentPath === Routes.about_us ? activeItem : {color: 'white'}}>
                        O nas
                    </Button>
                  </Link>
                  <Link style={itemStyles} onClick={() => this.handleAddressChange(Routes.contact)} color="inherit" to={Routes.contact}>
                    <Button style={currentPath === Routes.contact ? activeItem : {color: 'white'}}>
                        Kontakt
                    </Button>
                  </Link>
                  <Link style={itemStyles} onClick={() => this.handleAddressChange(Routes.configurator)}  color="inherit" to={Routes.configurator}>
                    <Button variant={'contained'} color="secondary">
                        Konfigurator
                    </Button>
                  </Link>
                </div>

              </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
              <Hidden smUp implementation="css">
                <Drawer
                  container={this.props.container}
                  variant="temporary"
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <main className={classes.content} style={{ marginTop:140}}>
              <div className={classes.toolbar} />
            </main>
          </div>
 
          <Route exact path={Routes.home} component={Home} />
          <Route path={Routes.configurator} component={Configurator} />
          <Route path={Routes.tech_tips} component={TechTips} />
          <Route path={Routes.about_us}  component={AboutUs} />
          <Route path={Routes.contact} component={Contact} />
          <Route path={Routes.rodo} component={Rodo} />
          <Route path={Routes.tipsEdit} component={TipsEdit} />
        </div>
      </Router>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);