import React from 'react';
// @ts-ignore
import { render } from 'react-snapshot';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { MuiThemeProvider } from '@material-ui/core/styles';

import theme from './components/theme';

render(
    <MuiThemeProvider theme={theme}>
        <App />
    </MuiThemeProvider>
, document.getElementById('root'));

serviceWorker.unregister();
