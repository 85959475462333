import { Theme } from "@material-ui/core/styles";

export default (theme: Theme) => ({
    conainer: {
      width: '100%',
    },
    svgElem: {
      [theme.breakpoints.up("md")]: {
        width: '100%',
        maxWidth: 600 * 0.8,
        height: 775 * 0.8,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      display: 'block',
      width: 600 /3,
      height: 775 /3,
      marginLeft: 'auto',
      marginRight: 'auto',
      border: `1px solid ${theme.palette.primary.dark}`
    } as any,
    
    sliderElem: {
      width: 300,
      padding: 15,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    confWrapper: {
      marginTop: 25,
      [theme.breakpoints.up("md")]: {
        width: 596,
        float: 'left',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      width: '100%',
    } as any,
    infoWrapper: {
      clear: 'both',
      textAlign: 'center',
      userSelect: 'none',
    } as any,
    prod: {
      [theme.breakpoints.up("md")]: {
        float: 'left',
        width: 'calc(100% - 611px)',
      }
    } as any,
    prodWrapper: {
      margin: '15px 0 0 0',
      [theme.breakpoints.up("md")]: {
        display: 'block',
        clear: 'none',
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    } as any,
    imgItem: {
      height: '100%',
    } as any,
    cardElem: {
      marginTop: 8,
      width: 350,
    } as any,
    guidCard: {
        position: 'absolute',
        width: 700,
        height: 600,
        top: '50%',
        left: '50%',
        marginLeft: -350,
        marginTop: -350,
        [theme.breakpoints.down('md')]: {
            width: 500,
            marginLeft: -300,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: 0,
            left: 0,
        },
    } as any,
    btnsGuide: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    } as any,
  });