import config from '../config';

const post = async (endpoint: string, body: object) => {
    return fetch(`${config.serverAddress}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
}

const get = async (endpoint: string) => {
  return fetch(`${config.serverAddress}/${endpoint}`, {
    method: 'GET',
  })
}

export { post, get } 