import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import { withStyles, Theme, Grid } from '@material-ui/core';

const style = (theme: Theme) => ({

})

class Rodo extends Component {
  render() {
    return (
      <Grid item>
        <Typography align='center' paragraph color="inherit">
        RODO<br /><br />
        Podane przez Ciebie dane osobowe będą przetwarzane w celu wykonania umowy kupna-sprzedaży – przetwarzanie jest niezbędne do wykonania umowy, aż do czasu zakończenia umowy
        Posiadasz prawo dostępu do treści swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych,
        prawo wniesienia sprzeciwu (np. jeśli dane są przetwarzane na podstawie Twojej zgody lub w celach marketingowych czy udostępniania Twoich danych);
        posiadasz prawo do cofnięcia zgody w dowolnym momencie.
        W celu cofnięcia zgody możesz skontaktować się z punktem obsługi klienta w biurze,
        pod adresem Ciecierzyn 85 lub wysyłając
        wiadomość e-mail na adres: mdwkladykominowe@gmail.com.

        Podanie przez Ciebie danych osobowych jest w pełni dobrowolne.
        Niepodanie danych osobowych będzie skutkowało niemożliwością pełnego wykonania zobowiązań,
        przyjętych przez MD wkłady kominowe w ramach zawieranych z Tobą umów.
      </Typography>
      </Grid>
    )
  }
}
export default withStyles(style)(Rodo);