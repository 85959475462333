import React, { Component } from 'react'
import { withStyles, Theme, Typography, Grid, Card, Link, CardHeader, CardContent, TextField, CardActions, Button, Snackbar, Checkbox } from '@material-ui/core';
import { Person, Map, Phone, Email } from '@material-ui/icons';

import MySnackbarContentWrapper from '../components/SnackbarContentWrapper';
import isEmail from 'validator/lib/isEmail';
import { post } from '../components/request';

const style = (theme: Theme) => ({
  Wrapper: {},
  imgWrp: {
    width: '100%',
    height: 350,
    background: 'url(/img/contact/big.jpg) 0 0',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      background: 'url(/img/contact/small.jpg) 0 0',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('lg')]: {
      background: 'url(/img/contact/medium.jpg) 0 0',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }
  },
  input: {
    marginBottom: 5,
  },
  mapGrid: {
    [theme.breakpoints.only('md')]: {
      marginTop: -200,
      height: 500
    }
  }
})

class Contact extends Component<any> {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    rodo: false,
    snackBarSended: false,
    snackBarError: false,
  }

  handleChange = (name: any) => (event: any) => {
    this.setState({ [name]: event.target.value });
  }

  handleSendMessage = () => {
    const { name, email, subject, message, rodo } = this.state;

    if(!(name.length && isEmail(email) && subject.length && message.length && rodo)) return;

    post('contact', { name, email, subject, message })
    .then((res) => {
      res.json().then(r => {

        if (r.sended) {
          this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
            snackBarSended: true,
          })
        } else {
          this.setState({snackBarError: true})
        }
      })

    }).catch(err => {
      this.setState({snackBarError: true})
    });
  }

  handleCloseSnackBar = () => {
    this.setState({snackBarSended: false, snackBarError: false});
  }

  render() {
    const {classes} = this.props;
    const { name, email, subject, message } = this.state;

    return (
      <div className={classes.Wrapper}>


        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackBarError}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSnackBar}
            variant="error"
            message="Błąd serwera"
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackBarSended}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSnackBar}
            variant="success"
            message="Wysłano pomyślnie"
          />
        </Snackbar>
        <div className={classes.imgWrp}>
          <Grid style={{height: 350}} container justify="center" alignItems='center'>
            <Card style={{borderRadius: 0}}>
              <Typography style={{padding: 20}} variant="h2" align='center' color="primary" noWrap>
                Kontakt
              </Typography>
            </Card>
          </Grid>
        </div>
        <Grid container style={{padding: 4}} >
          <Grid item sm={12} xs={12} md={6} lg={4}>
            <Typography style={{padding: 20}} variant="h4" align='center' color="primary" noWrap>
              Dane firmy
            </Typography>
            <Typography style={{padding: 20}} variant='h6' align='center' color="primary" noWrap>
              <Person style={{verticalAlign: 'sub'}} color='primary' /> M.D. Artur Porębski
            </Typography>
            <Typography style={{padding: 20}} variant='h6' align='center' color="primary">
                <Map style={{verticalAlign: 'sub'}} color='primary' /> Ciecierzyn 85 <br />
                21-003 Ciecierzyn
            </Typography>
            <Typography style={{padding: 20}} variant='h6' align='center' color="primary">
              <Phone style={{verticalAlign: 'sub'}} color='primary' /> Telefon: <Link href='tel: 817561382'> 81 756 13 82</Link><br />
              Komórkowy: <Link href='tel: 510035663'> 510 035 663</Link>
            </Typography>
            <Typography style={{padding: 20}} variant='h6' align='center' color="primary">
              <Email style={{verticalAlign: 'sub'}} color="primary" /> Email: <Link href='mailto: mdwkladykominowe@gmail.com'> mdwkladykominowe@gmail.com</Link><br />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sm={12}>
            <Typography style={{padding: 20}} variant='h4' align='center' color="primary" noWrap>
              Napisz do nas:
            </Typography>
            <Card style={{maxWidth: 450, marginLeft: 'auto', marginRight: 'auto'}}>
              <CardHeader style={{ textAlign: 'center' }} title='Wyślij wiadomość' />
              <CardContent>
                
                <TextField fullWidth label="Imię:" onChange={this.handleChange('name')} value={name} required className={classes.input} variant='outlined' /><br />

                <TextField fullWidth label="Email:" onChange={this.handleChange('email')} value={email}  required className={classes.input} variant='outlined' /><br />

                <TextField fullWidth label="Temat:"  onChange={this.handleChange('subject')} value={subject} required className={classes.input} variant='outlined'/><br />

                <TextField fullWidth label="Wiadomość:"  onChange={this.handleChange('message')} value={message} required className={classes.input} id='message' variant='outlined' multiline rows='4'/>
                <Typography variant='caption' align='left' color='inherit' paragraph><Checkbox style={{verticalAlign: 'sub'}} color='primary' onChange={this.handleChange('rodo')} />* Wyrażam zgodę na przetwarzanie danych i zapoznanałem się z klauzulą RODO.</Typography>
                <Typography variant='caption' align='left' color='inherit' paragraph>* - wymagane</Typography>

              </CardContent>
              <CardActions style={{display: 'flex', justifyContent: 'right'}}>
                <Button onClick={this.handleSendMessage} fullWidth variant='contained' color='secondary'>Wyślij!</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item className={classes.mapGrid} sm={12} xs={12} md={6} lg={4}>
            <Typography style={{padding: 20}} variant='h4' align='center' color="primary">
              Znajdziesz nas tutaj:
            </Typography>
            <iframe
              title={'Mapa Google'}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4194.6641080034715!2d22.592740073882705!3d51.30814009421921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472250807c1a518b%3A0xb45a750be5cde294!2sCiecierzyn+85%2C+21-003+Ciecierzyn!5e0!3m2!1sen!2spl!4v1550758188942"
              width="100%"
              style={{border:0, height: 'calc(100% - 79px)'}}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}
export default withStyles(style)(Contact);
