import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/lab/Slider';
import { Typography, Card, CardHeader, CardMedia, CardContent, CardActions, Button, Modal, TextField, Snackbar, Grid, Checkbox } from '@material-ui/core';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { ShoppingBasket, Cancel, NavigateNext, Person, Mail, ArrowBack } from '@material-ui/icons';

import imageElem from '../services/imageElem';
import { post } from '../components/request';
import MySnackbarContentWrapper from '../components/SnackbarContentWrapper';

import images from './images';
import styles from './configStyles';

interface ConfiguratorState {
  ratio: number,
  fake_ratio: number,
  svgImages: imageElem[],
  summaryModal: boolean,
  guideModal: any | null,
  guideModalPage: number,
  products: any[],
  toData: boolean,
  snackBarError: boolean,
  snackBarSended: boolean,

  //user data
  name: string,
  phone: string,
  email: string,
  rodo: boolean
}



class Configurator extends Component<any, any, any> {
  state: ConfiguratorState = {
    ratio: 1,
    fake_ratio: 0.75,
    svgImages: [],
    summaryModal: false,
    guideModal: window.localStorage && window.localStorage.getItem('guide'),
    guideModalPage: 0,
    products: [],
    toData: false,
    snackBarError: false,
    snackBarSended: false,

    rodo: false,
    name: '',
    phone: '',
    email: '',
  }

  handleCloseSnackBar = () => {
    this.setState({ snackBarError: false, snackBarSended: false });
  }

  handleAddActions = (evt: any) => {
    let selectedElement: any, offset: any, transform: any;
    const tgt: any = evt.target;
    const svg: any = tgt.ownerSVGElement;

    let tapped: any = false;

    const getMousePosition = (evt: any) => {
      const CTM = svg.getScreenCTM();

      if (evt.touches) { evt = evt.touches[0]; }

      return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d
      };
    }


    const startDragMobile = (evt: any) => {
      if (evt.target.classList.contains('draggable')) {
        if (!tapped) {
          tapped = setTimeout(function () {
            tapped = null;
          }, 300);
        } else {
          clearTimeout(tapped);
          tapped = null;

          this.handleRemove(evt);
        }

        selectedElement = evt.target;

        const transforms = selectedElement.transform.baseVal;

        offset = getMousePosition(evt);

        if (transforms.length === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
          const translate = svg.createSVGTransform();

          translate.setTranslate(0, 0);
          selectedElement.transform.baseVal.insertItemBefore(translate, 0);
        }

        transform = transforms.getItem(0);
        offset.x -= transform.matrix.e;
        offset.y -= transform.matrix.f;
      }
    }

    function startDrag(evt: any) {
      if (evt.target.classList.contains('draggable')) {
        selectedElement = evt.target;

        const transforms = selectedElement.transform.baseVal;

        offset = getMousePosition(evt);

        if (transforms.length === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
          const translate = svg.createSVGTransform();

          translate.setTranslate(0, 0);
          selectedElement.transform.baseVal.insertItemBefore(translate, 0);
        }

        transform = transforms.getItem(0);
        offset.x -= transform.matrix.e;
        offset.y -= transform.matrix.f;
      }
    }

    function drag(evt: any) {
      if (selectedElement) {
        evt.preventDefault();
        var coord = getMousePosition(evt);
        transform.setTranslate(coord.x - offset.x, coord.y - offset.y);
      }
    }

    function endDrag(evt: any) {
      selectedElement = null;
    }

    svg.addEventListener('mousedown', startDrag);
    svg.addEventListener('mousemove', drag);
    svg.addEventListener('mouseup', endDrag);
    svg.addEventListener('mouseleave', endDrag);

    svg.addEventListener('dblclick', this.handleRemove);

    // mobile
    svg.addEventListener('touchstart', startDragMobile);
    svg.addEventListener('touchmove', drag);
    svg.addEventListener('touchend', endDrag);
    svg.addEventListener('touchleave', endDrag);
    svg.addEventListener('touchcancel', endDrag);
  }

  handleClickItem = (el: any) => {
    this.setState({ svgImages: [...this.state.svgImages, el] });
  }

  handleRemove = (el: any) => {
    if (el) {
      const { target } = el;

      el.preventDefault();

      if (target.classList.contains('draggable')) {
        const elemIndex = parseInt(target.dataset.index);
        const newArr: any = this.state.svgImages;
        newArr[elemIndex] = null;
        this.setState({ svgImages: newArr });
      }
    }
  }

  handleRemoveBasket = (el: any) => {
    const newArr: any = this.state.svgImages.filter((elem: any) => (elem.src !== el.src));

    this.setState({ svgImages: newArr });
  }

  toggleSummary = () => {
    this.setState({ summaryModal: !this.state.summaryModal })
  }

  toggleToData = () => {
    this.setState({ toData: !this.state.toData })
  }

  sendQuery = (products: any) => {
    const { name, phone, email, rodo } = this.state;

    if (!(name.length || phone.length || email.length || rodo))
      return;

    post('makeOffer', { name, phone, email, products: products.map((el: any) => ({ count: el.count, item_name: el.item_name })) })
      .then((res) => {
        res.json().then(r => {

          if (r.sended) {
            this.setState({
              summaryModal: false,
              guideModalPage: 0,
              toData: false,
              name: '',
              phone: '',
              email: '',
              snackBarSended: true,
            })
          } else {
            this.setState({ snackBarError: true })
          }
        })

      }).catch(err => {
        this.setState({ snackBarError: true })
      });

  }

  handleChange = (name: any) => (event: any) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const {
      ratio,
      fake_ratio,
      svgImages,
      guideModal,
      guideModalPage,
      summaryModal,
    } = this.state;
    const { classes } = this.props;

    const currElems = svgImages.filter(el => el && el.item_length);
    const currLength = currElems.reduce((total, curr) => total + curr.item_length, 0);
    const totalLength = currElems.length > 1 ? currLength - (50 * (currElems.length - 1)) : currLength;

    const guideContent: any = {
      0: (
        <div>
          <Typography align='center' variant="title" color="inherit" noWrap>
            Dodawanie Elementów
          </Typography>
          <Typography align='left' variant='subtitle1' color="inherit">
            Aby dodać element do konfiguratora, kliknij w przycisk:
          </Typography>
          <Card className={classes.cardElem}>
            <CardMedia style={{ backgroundSize: "contain", height: "100px", width: "33px", float: "left", margin: "10px" }} className={classes.imgItem} image={images[0].src} title={images[0].item_description} />
            <CardHeader style={{ padding: 10 }} title={images[0].item_name} />
            <CardContent>{images[0].item_description}</CardContent>
            <CardActions style={{ clear: 'both' }}>
              <Button
                variant="contained"
                color='primary'
                onClick={() => this.setState({ guideModalPage: guideModalPage + 1 })}
              >Dodaj do konfiguratora</Button>
            </CardActions>
          </Card>
        </div>
      ),
      1: (
        <div>
          <Typography align='center' variant="title" color="inherit" noWrap>
            Poruszanie elementami
          </Typography>
          <Typography align='left' variant='subtitle1' color="inherit">
            Aby przesunąć element na konfiguratorze, kliknij i porusz myszką lub przesuń palec (urządzenia mobilne):
          </Typography>
          <svg onLoad={this.handleAddActions} width={200} height={200}>
            <image xlinkHref='img/c_home.jpg' width='100%' height='100%' />
            <image
              className='draggable'
              data-index={0}
              x={'45%'}
              y={'25%'}
              key={`image-${0}`}
              xlinkHref={images[0].src}
              width={images[0].width * 0.5}
              height={images[0].height * 0.5}
            />
          </svg>
        </div>
      ),
      2: (
        <div>
          <Typography align='center' variant="title" color="inherit" noWrap>
            Skalowanie elementów
          </Typography>
          <Typography align='left' variant='subtitle1' color="inherit">
            Aby lepiej wykorzystać przestrzeń, mozesz dowolnie zmieniać rozmiar elementów przy pomocy suwaka. <br />
            <b>Uwaga:</b> Faktyczny rozmiar elementów się nie zmienia, jedynie ich skala w konfiguratorze.
          </Typography>
          <Typography align='center' variant="subtitle2" color="inherit" noWrap>
            Skala elementów
          </Typography>
          <Slider
            className={classes.sliderElem}
            value={fake_ratio}
            min={0.25}
            max={1.25}
            onChange={(ev, value) => { this.setState({ fake_ratio: value }) }}
          />
          <svg onLoad={this.handleAddActions} width={200} height={200}>
            <image xlinkHref='img/c_home.jpg' width='100%' height='100%' />
            <image
              className='draggable'
              data-index={0}
              x={'45%'}
              y={'25%'}
              key={`image-${0}`}
              xlinkHref={images[0].src}
              width={images[0].width * fake_ratio}
              height={images[0].height * fake_ratio}
            />
          </svg>
        </div>
      ),
      3: (
        <div>
          <Typography align='center' variant="title" color="inherit" noWrap>
            Usuwanie elementów
          </Typography>
          <Typography align='left' variant='subtitle1' color="inherit">
            Aby usunąć dany element, kliknij dwa razy (moze nie działać na urządzeniach mobilnych): <br />
          </Typography>
          <svg width={200} height={200}>
            <image xlinkHref='img/c_home.jpg' width='100%' height='100%' />
            <image
              data-index={0}
              x={'45%'}
              y={'25%'}
              xlinkHref={images[0].src}
              width={images[0].width * 0.75}
              height={images[0].height * 0.75}
              onDoubleClick={(ev: any) => { ev.target.parentNode.removeChild(ev.target) }}
            />
          </svg>
        </div>
      ),
      4: (
        <div>
          <Typography align='center' variant="title" color="inherit" noWrap>
            Składanie zapytania o wycenę
          </Typography>
          <Typography align='left' variant='subtitle1' color="inherit">
            Aby przejść do podsumowania i zapytać o cenę Twojego zestawu, kliknij:
          </Typography>
          <div style={{ width: '100%', padding: 25, textAlign: 'center' }}>
            <Button variant='contained' color='secondary'> Zapytaj o wycenę </Button>
          </div>
        </div>
      ),
    }

    let map = new Map();

    svgImages.forEach((el: any) => {
      if (el && el.item_name) {
        var currEl = map.get(el.item_name) || el;

        currEl.count = svgImages.filter((elem => elem && elem.item_name === el.item_name)).length;

        map.set(el.item_name, { count: currEl, ...el })
      }
    });
    const values: any = map.values();
    const products: any = [...values];

    const imagesHeight = svgImages
      .map(el => (el && el.height) || 0)
      .reduce((tot, curr) => tot + curr, 0);
    const svgMap: any = this.refs.svgMap;
    const svgHeight = svgMap && svgMap.clientHeight * 0.89;
    const elemsRatedHeight = imagesHeight * ratio;
    const screenElemsDiff = elemsRatedHeight - svgHeight;

    if(svgHeight && screenElemsDiff > 0 ) {

      const ratioDifference = screenElemsDiff / svgHeight;

      this.setState({
        ratio: ratio - ratioDifference
      });
    }

    return (
      <div className={classes.conainer}>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackBarError}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSnackBar}
            variant="error"
            message="Błąd serwera"
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackBarSended}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSnackBar}
            variant="success"
            message="Wysłano pomyślnie"
          />
        </Snackbar>

        <Modal open={!guideModal}>
          <Card className={classes.guidCard}>
            <CardHeader title={'Samouczek'} subheader={`${guideModalPage + 1} / 5`} subheaderTypographyProps={{ align: 'center' }} titleTypographyProps={{ variant: 'h3', align: 'center' }} />
            <CardContent>{guideContent[guideModalPage]}</CardContent>
            {guideModalPage !== 4 ? <CardActions className={classes.btnsGuide}>
              <Button onClick={() => { window.localStorage && window.localStorage.setItem('guide', "true"); this.setState({ guideModal: "true" }) }}>Pomiń</Button>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  this.setState({ guideModalPage: guideModalPage + 1 });
                }}
              > Dalej </Button>
            </CardActions> : <CardActions className={classes.btnsGuide}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => { window.localStorage && window.localStorage.setItem('guide', "true"); this.setState({ guideModal: "true", svgImages: [] }) }}
                > Zamknij </Button>
              </CardActions>}
          </Card>
        </Modal>

        <Modal open={summaryModal} onClose={this.toggleSummary}>
          <Card className={classes.guidCard}>
            <CardHeader avatar={<ShoppingBasket color='primary' />} title={'Twoje elementy do wyceny:'} />
            <div style={{ overflow: 'auto', maxHeight: 475 }}>
              {products.map(
                (el: any, i: number) =>
                  el && el.src ?
                    <Card style={{ position: 'relative' }} key={`card-${i}`} className={classes.cardElem}>
                      <CardMedia style={{ backgroundSize: "contain", height: 100, width: 33, float: "left", margin: 10 }} className={classes.imgItem} image={el.src} title={el.item_description} />
                      <CardHeader style={{ padding: 10 }} title={el.item_name} />
                      <CardContent>Sztuk: {el.count}</CardContent>
                      <CardActions style={{ position: 'absolute', bottom: 0, right: 0 }}>
                        <Button variant="contained" color='primary' onClick={() => this.handleRemoveBasket(el)} >
                          <Cancel style={{ marginRight: 5 }} color='inherit' /> Usuń
                      </Button>
                      </CardActions>
                    </Card> : null
              )}
            </div>
            <CardActions style={{ position: 'absolute', bottom: 0, right: 0 }}>
              <Button variant="contained" color='secondary' onClick={() => this.toggleToData()} >
                Dalej <NavigateNext style={{ marginLeft: 5 }} color='inherit' />
              </Button>
            </CardActions>
          </Card>
        </Modal>

        <Modal open={this.state.toData} onClose={this.toggleToData}>
          <Card className={classes.guidCard}>
            <CardHeader avatar={<Person color='secondary' />} title={'Dane do kontaktu:'} />
            <CardContent>
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                  <TextField onChange={this.handleChange('name')} label='Twoje Imię:' error={!this.state.name} value={this.state.name} required /><br />
                  <TextField onChange={this.handleChange('phone')} label="Twój telefon:**" error={!((this.state.phone.length || this.state.email.length) && (isMobilePhone(this.state.phone, 'pl-PL') || isEmail(this.state.email)))} value={this.state.phone} /><br />
                  <TextField onChange={this.handleChange('email')} label='Twój e-mail:**' error={!((this.state.phone.length || this.state.email.length) && (isMobilePhone(this.state.phone, 'pl-PL') || isEmail(this.state.email)))} value={this.state.email} /><br />
                  <Typography variant='caption' align='left' color='inherit' paragraph><Checkbox style={{ verticalAlign: 'sub' }} color='primary' onChange={this.handleChange('rodo')} />* Wyrażam zgodę na przetwarzanie danych i zapoznanałem się z klauzulą <Link target='blank' to="/rodo.html">RODO</Link>.</Typography>
                  <Typography align='left' variant='caption' paragraph>
                    * - pole wymagane <br />
                    ** - jedno z pól wymagane
                  </Typography>
                </div>
              </div>
            </CardContent>
            <CardActions style={{ position: 'absolute', bottom: 0, right: 0 }}>
              <Button onClick={() => this.toggleToData()} >
                <ArrowBack /> Cofnij
              </Button>
              <Button variant="contained" color='secondary' onClick={() => this.sendQuery(products)} >
                Wyślij <Mail style={{ marginLeft: 5 }} color='inherit' />
              </Button>
            </CardActions>
          </Card>
        </Modal>

        <Grid container justify='center'>
          <Grid item md={7} lg={5}>
            <Typography align='center' variant="subtitle1" color="inherit" noWrap>
              Skala elementów
            </Typography>
            <Slider
              className={classes.sliderElem}
              value={ratio}
              min={0.25}
              max={1.25}
              onChange={(ev, value) => { this.setState({ ratio: value }) }}
            />
            <svg onLoad={this.handleAddActions} ref='svgMap' className={classes.svgElem}>
              <image xlinkHref='img/c_home.jpg' width='100%' height='100%' />
              {
                svgImages.map((el: imageElem, i: number, arr: imageElem[]) => {
                  if(!el || !arr) return null;

                  let currHeight = arr
                    .map(el => (el && el.height) || 0)
                    .splice(0, i + 1)
                    .reduce((tot, curr) => tot + curr, 0);

                  //currHeight = i > 0 ? currHeight : 0;

                  console.log({currHeight})

                  return (<image
                    className='draggable'
                    data-index={i}
                    x={'29%'}
                    y={`calc(94% - ${currHeight * ratio}px)`}
                    key={`image-${i}`}
                    xlinkHref={el.src}
                    width={el.width * ratio}
                    height={el.height * ratio}
                  />);
                })
              }
            </svg>
            <Typography align='center' variant="subtitle1" color="inherit" noWrap>
              Długość elementów: {totalLength}mm ({totalLength / 10}cm).
              <br />
              {svgImages.length && svgImages.find((el): any => el && el.src) ? <Button onClick={this.toggleSummary} variant='contained' color='secondary'> Zapytaj o wycenę </Button> : <Button disabled disableRipple variant='contained' color='inherit'> Zapytaj o wycenę </Button>}
            </Typography>
          </Grid>

          <Grid item container md={5} lg={7}>
            <Grid item style={{flex: '1 1 100%'}}>
              <Typography align='center' variant="title" color="primary" noWrap>
                Produkty
              </Typography>
            </Grid>
              {
                images.map(
                  (el, i) =>
                  <Grid key={`grid-${i}`} style={{flex: '1 1 300px'}} item>
                    <Card key={`card-${i}`} className={classes.cardElem}>
                        <CardMedia style={{ backgroundSize: "contain", height: "100px", width: "33px", float: "left", margin: "10px" }} className={classes.imgItem} image={el.src} title={el.item_description} />
                        <CardHeader style={{ padding: 10 }} title={el.item_name} />
                        <CardContent>{el.item_description}</CardContent>
                        <CardActions style={{ clear: 'both' }}>
                          <Button variant="contained" color='primary' onClick={() => this.handleClickItem(el)} >Dodaj do konfiguratora</Button>
                        </CardActions>
                      </Card>
                  </Grid>
                )
              }
          </Grid>
        </Grid>

      </div>
    )
  }
}

export default withStyles(styles)(Configurator);
