import React, { Component } from 'react'
import {
  Grid,
  withStyles,
  Theme,
  Typography,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Checkbox,
  CardActions,
  Button,
  Fade
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ScrollMagic from 'scrollmagic';

import isEmail from 'validator/lib/isEmail';

import { post } from '../components/request';
import { Link } from 'react-router-dom';

const style = (theme: Theme): Record<string, CSSProperties> => ({
  firstElem: {
    position: 'relative',
    height: '66vw',
    maxHeight: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  firstImage: {
    position: 'absolute',
    height: '66vw',
    maxHeight: '80vh',
    objectFit: 'cover'
  },
  firstText: {
    padding: 15,
    backgroundColor: 'white',
    boxSizing: 'inherit',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      margin: 15,
    },
    [theme.breakpoints.up('sm')]: {
      padding: 35,
      '& p': {
        display: 'block !important',
      },
      '& h6::after': {
        margin: "3rem auto 3rem !important",
      }
    },
    '& p': {
      display: 'none',
    },
    '& h6::after': {
      display: 'block',
      content: '""',
      margin: "15px auto 0",
      width: "6rem",
      height: 2,
      backgroundColor: '#006484',
    }
  },
  input: {
    marginBottom: 5,
  },
  graphics: {
    maxWidth: 250,
    display: 'block',
    margin: 'auto',
  },
  graphicWrapper: {
    '& h6::after': {
      display: 'block',
      content: '""',
      margin: "15px auto",
      width: "6rem",
      height: 2,
      backgroundColor: '#006484',
      [theme.breakpoints.up('sm')]: {
        '& h6::after': {
          margin: "3rem auto 3rem !important",
        },
      },
    },
  },
  contactForm: {
    backgroundImage: 'url(/img/home/02-small.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100vw',
    padding: 5,
    [theme.breakpoints.down('sm')]:{
      maxHeight: '66vw',
      paddingTop: '150px !important'
    },
    [theme.breakpoints.up('sm')]: {
      backgroundImage: 'url(/img/home/02-medium.jpg)',
      padding: 25,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: 'url(/img/home/02-big.jpg)',
    },
  }
});

class Home extends Component<any> {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    rodo: false,
  }

  handleChange = (name: any) => (event: any) => {
    this.setState({ [name]: event.target.value });
  }


  handleSendMessage = () => {
    const { name, email, subject, message, rodo } = this.state;

    if (!(name.length && isEmail(email) && subject.length && message.length && rodo)) return;

    post('contact', { name, email, subject, message })
      .then((res) => {
        res.json().then(r => {

          if (r.sended) {
            this.setState({
              name: '',
              email: '',
              subject: '',
              message: '',
              snackBarSended: true,
            })
          } else {
            this.setState({ snackBarError: true })
          }
        })

      }).catch(err => {
        this.setState({ snackBarError: true })
      });
  }

  componentDidMount(){
    const controller = new ScrollMagic.Controller();

    const scene1 = new ScrollMagic.Scene({
      triggerElement: '#s1',
      triggerHook: 0.7,
      reverse: false
    })
    .setClassToggle('#s1', 'active')
    .addTo(controller);

    const scene2 = new ScrollMagic.Scene({
      triggerElement: '#s2',
      triggerHook: 0.7,
      reverse: false
    })
    .setClassToggle('#s2', 'active')
    .addTo(controller);

    const scene3 = new ScrollMagic.Scene({
      triggerElement: '#s3',
      triggerHook: 0.7,
      reverse: false
    })
    .setClassToggle('#s3', 'active')
    .addTo(controller);

    const pinImage = new ScrollMagic.Scene({
      triggerElement: '#p1',
      triggerHook: 0,
      duration: '300px'
    }).setPin('#p1').addTo(controller);

  }

  render() {
    const { classes } = this.props;
    const { name, email, subject, message } = this.state;

    return (
      <Grid container justify="center" alignContent='center'>
        <Grid item xs={12} className={classes.firstElem}>
          <img
            id="p1"
            className={classes.firstImage}
            src="/img/home/01-big.jpg"
            alt=""
            width="100%"
            height='66vw'
            srcSet={`/img/home/01-small.jpg 425w, /img/home/01-medium.jpg 851w, /img/home/01-big.jpg  2642w`}
          />
          <Fade in timeout={340}>
            <div className={classes.firstText}>
              <Typography variant='h6' align='center'>
                Wkłady kominowe jakich potrzebujesz
            </Typography>
              <p>Jesteśmy firmą produkującą blaszane elementy do budowy komina.</p>
            </div>
          </Fade>
        </Grid>
        <Grid container style={{ background: '#f3f3eafa', zIndex: 3 }} item xs={12}>
          <Grid item xs={12} sm={12} md={4}>
            <div id="s1" className={classes.graphicWrapper}  >
              <img className={classes.graphics} src="/img/home/accept.svg" alt="" />
              <Typography variant='h6' align='center'>
                Wysoka jakość
            </Typography>
              <Typography paragraph variant='subheading' align='center'>
                Niskiej jakości materiały? To u konkurencji, my dostarczamy jedynie najlepsze rozwiązania.
            </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div id="s2" className={classes.graphicWrapper} >
              <img className={classes.graphics} src="/img/home/shop.svg" alt="" />
              <Typography variant='h6' align='center'>
                Ekspresowa dostawa
            </Typography>
              <Typography paragraph variant='subheading' align='center'>
                Z nami dopiero będziesz wiedział czym jest szybka dostawa.
            </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div id="s3" className={classes.graphicWrapper}>
              <img className={classes.graphics} src="/img/home/symbol.svg" alt="" />
              <Typography variant='h6' align='center'>
                Szybka odpowiedź
            </Typography>
              <Typography paragraph variant='subheading' align='center'>
                Nie wierzysz? Napisz, przekonaj się jak szybko potrafimy pomóc.
            </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item className={classes.contactForm}>
            <Card style={{ maxWidth: 450, margin: 'auto' }}>
              <CardHeader style={{ textAlign: 'center' }} title='Wyślij wiadomość' />
              <CardContent>
                <TextField fullWidth label="Imię:" onChange={this.handleChange('name')} value={name} required className={classes.input} variant='outlined' /><br />

                <TextField fullWidth label="Email:" onChange={this.handleChange('email')} value={email} required className={classes.input} variant='outlined' /><br />

                <TextField fullWidth label="Temat:" onChange={this.handleChange('subject')} value={subject} required className={classes.input} variant='outlined' /><br />

                <TextField fullWidth label="Wiadomość:" onChange={this.handleChange('message')} value={message} required className={classes.input} id='message' variant='outlined' multiline rows='4' />
                <Typography variant='caption' align='left' color='inherit' paragraph><Checkbox style={{ verticalAlign: 'sub' }} color='primary' onChange={this.handleChange('rodo')} />* Wyrażam zgodę na przetwarzanie danych i zapoznanałem się z klauzulą <Link target='blank' to="/rodo.html">RODO</Link>.</Typography>
                <Typography variant='caption' align='left' color='inherit' paragraph>* - wymagane</Typography>

              </CardContent>
              <CardActions style={{ display: 'flex', justifyContent: 'right' }}>
                <Button onClick={this.handleSendMessage} fullWidth variant='contained' color='secondary'>Wyślij!</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(style)(Home);