import React, { Component } from 'react'
import { withStyles, Theme, Typography, Grid, Card, Divider } from '@material-ui/core';

const style = (theme: Theme) => ({
  Wrapper: {},
  imgWrp: {
    width: '100%',
    height: 350,
    background: 'url(/img/aboutUs/big.jpg) 0 0',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      background: 'url(/img/aboutUs/small.jpg) 0 0',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('lg')]: {
      background: 'url(/img/aboutUs/medium.jpg) 0 0',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }
  },
  input: {
    marginBottom: 5,
  },
  mapGrid: {
    [theme.breakpoints.only('md')]: {
      marginTop: -100,
      height: 500
    }
  }
})
 class AboutUs extends Component<any> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.Wrapper}>
        <div className={classes.imgWrp}>
          <Grid style={{height: 350}} container justify="center" alignItems='center'>
            <Card style={{borderRadius: 0}}>
              <Typography style={{padding: 20}} variant="h2" align='center' color="primary" noWrap>
                O nas
              </Typography>
            </Card>
          </Grid>
          <Grid container justify='center'>
            <Grid item sm={7}>
              <Typography style={{marginTop: 30}} variant="h3" align='center' color="primary">
                <b>Kim jesteśmy?</b>
              </Typography>
              <Divider variant='middle' style={{margin: '25px 0'}} />
              <Typography variant='subtitle2' align='center' paragraph>
                MD wkłady kominowe to polska firma, zajmująca się dystrybucją wkładów kominkowych. <br />
                Działamy od ponad 10. lat na rynku polskim, oferując towary sprawdzonych dostawców.
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography style={{marginTop: 30}} variant="h3" align='center' color="primary">
                <b>Dlaczego powinieneś wybrać nas?</b>
              </Typography>
              <Divider variant='middle' style={{margin: '25px 0'}} />
              <Typography variant='subtitle2' align='center' paragraph>
                MD wkłady kominowe to pewność wyboru i gwarantowana wysoka jakość usług. <br />
                Zaufało nam już blisko kilkadziesiąt tysięcy klientów z całej Polski.
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography style={{marginTop: 30}} variant="h3" align='center' color="primary">
                <b>Pewność, jakość, gwarancja?</b>
              </Typography>
              <Divider variant='middle' style={{margin: '25px 0'}} />
              <Typography variant='subtitle2' align='center' paragraph>
                MD wkłady kominowe posiada certyfikat zakładowej kontroli produkcji, który zakłada tworzenie elementów o najlepszych parametrach.
              </Typography>
              <img src="img/cert.jpg" style={{maxWidth: 497, width: '100%', display: 'block', margin: 'auto'}} alt="Certyfikat zakładowej kontroli produkcji" />
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
export default withStyles(style)(AboutUs)